import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import { Button } from '../components/Button'
import Input, { Textarea, Select } from '../components/Input'
import { Container } from '../components/Contact'
import { Form } from '../components/Form'

const Fieldset = styled.div`
  /* margin: -0.375rem; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  h4 {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    margin: 30px 0 0;
  }

  /* @media (min-width: 940px) {
    margin: -0.5rem;
  } */
`

interface Props {
  children?: React.ReactChild
  title?: string
  didFindOut?: boolean
  showMessage?: boolean
  showSuburb?: boolean
  content?: string
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    showMessage: true,
    content: '',
    didFindOut: false,
    showSuburb: true,
  }

  state = {
    buttonMessage: `Get my free quote`,
    email: '',
    message: '',
    name: '',
    phone: '',
    suburb: '',
    didFindOut: '',
    website: '',
    installationDIY: '',
    councilApproval: '',
    footings: '',
    roofAttachedOn: '',
    width: '',
    length: '',
    height: '',
    enquiryAbout: '',
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    // @ts-ignore
    // fbq('track', 'Lead', {
    //   content_category: 'form',
    //   content_name: window.location.pathname
    // })

    // @ts-ignore
    fbq('track', 'Lead')
    // // @ts-ignore
    // window.dataLayer.push({
    //   event: 'Pageview',
    //   pagePath: '/request-quote/online-estimate-main-form/',
    // })
    // @ts-ignore
    window.dataLayer.push({
      event: 'new_enquiry',
      location: 'online-estimate',
    })

    this.setState({ buttonMessage: 'Working...' })

    grecaptcha.ready(() => {
      grecaptcha
        .execute('6LdAzLQUAAAAAD0dSn0KvaouFCTYv8eDhj07jpNY', {
          action: 'homepage',
        })
        .then((token: string) => {
          fetch('/.netlify/functions/send-online-estimate', {
            body: JSON.stringify({
              'g-recaptcha-response': token,
              ...this.state,
            }),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error()
              }
              this.setState({ buttonMessage: 'Thank You!' })
            })
            .catch(() => {
              this.setState({ buttonMessage: 'Error - Try later!' })
            })
        })
    })
  }

  render() {
    const { type, title, showMessage, showSuburb, didFindOut } = this.props
    const isAudit = type === 'audit'

    return (
      <Layout>
        <Helmet>
          <title>Online Estimate - Just Patios</title>
          <meta
            name="description"
            content="Request an online estimate for your patio, deck, carport or room enclosure from Just Patios. Experienced builders. Call us for a cost effective free quote."
          />
        </Helmet>
        <Container>
          <h2>
            Online Estimate <i>Quote</i>
          </h2>

          <Form name="contactForm" method="post" onSubmit={this.handleSubmit}>
            <p style={{ fontSize: '1.75em' }}>
              Or give us a call on <a href="tel:1300284531">1300 284 531</a>
            </p>
            <p style={{ padding: '20px 0', fontSize: '1.2rem' }}>
              Please complete the form below to arrange a free online estimate
              quote for your new patio. We will call you as soon as possible to
              organise a convenient time to visit.
            </p>
            <Fieldset>
              <h4>Personal Details</h4>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required={true}
                value={this.state.name}
                onChange={this.handleChange}
              />

              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email address"
                required={true}
                value={this.state.email}
                onChange={this.handleChange}
              />
              <div>
                <Input
                  half={true}
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone number"
                  required={true}
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
                <Input
                  half={true}
                  type="text"
                  id="suburb"
                  name="suburb"
                  placeholder="Suburb"
                  required={true}
                  value={this.state.suburb}
                  onChange={this.handleChange}
                />
              </div>
              <Select
                id="enquiryAbout"
                name="enquiryAbout"
                onChange={this.handleChange}
                value={this.state.enquiryAbout}
              >
                <option value="" disabled={true}>
                  Enquiry about?
                </option>
                <option value="Flat Roof - Insulated">
                  Flat Roof - Insulated
                </option>
                <option value="Gable - Insulated">Gable - Insulated</option>
                <option value="Flat Roof - Uninsulated">
                  Flat Roof - Uninsulated
                </option>
                <option value="Gable - Uninsulated">Gable - Uninsulated</option>
                <option value="Deck">Deck</option>
                <option value="Flyover">Flyover</option>
                <option value="Carport">Carport</option>
              </Select>
              <h4>Additional Information</h4>
              <Input
                type="text"
                id="height"
                name="height"
                placeholder="Height (Highest Point)"
                required={true}
                value={this.state.height}
                onChange={this.handleChange}
              />
              <Input
                type="text"
                id="length"
                name="length"
                placeholder="Length (e.g Side Attaching to House)"
                required={true}
                value={this.state.length}
                onChange={this.handleChange}
              />
              <Input
                type="text"
                id="width"
                name="width"
                placeholder="Width (Roof Sheet Span Including Gutter)"
                required={true}
                value={this.state.width}
                onChange={this.handleChange}
              />
              <div>
                <Select
                  half={true}
                  id="roofAttachedOn"
                  name="roofAttachedOn"
                  onChange={this.handleChange}
                  value={this.state.roofAttachedOn}
                >
                  <option value="" disabled={true}>
                    Roof Attached On?
                  </option>
                  <option value="1 Side">1 Side</option>
                  <option value="2 Sides">2 Sides</option>
                  <option value="3 Sides">3 Sides</option>
                  <option value="Freestanding">Freestanding</option>
                  <option value="Flyover">Flyover</option>
                </Select>
                <Select
                  half={true}
                  id="footings"
                  name="footings"
                  onChange={this.handleChange}
                  value={this.state.footings}
                >
                  <option value="" disabled={true}>
                    Footings?
                  </option>
                  <option value="On Concrete">On Concrete</option>
                  <option value="On Deck">On Deck</option>
                  <option value="On Ground">On Ground</option>
                  <option value="Freestanding">Freestanding</option>
                  <option value="Flyover">Flyover</option>
                </Select>
              </div>
              <div>
                <Select
                  half={true}
                  id="councilApproval"
                  name="councilApproval"
                  onChange={this.handleChange}
                  value={this.state.councilApproval}
                >
                  <option value="" disabled={true}>
                    Is council approval required?
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Don't Know">Don't Know</option>
                </Select>
                <Select
                  half={true}
                  id="installationDIY"
                  name="installationDIY"
                  onChange={this.handleChange}
                  value={this.state.installationDIY}
                >
                  <option value="" disabled={true}>
                    Installation or DIY?
                  </option>
                  <option value="Installation Included">
                    Installation Included
                  </option>
                  <option value="DIY Installation Kit">
                    DIY Installation Kit
                  </option>
                </Select>
              </div>
              <h4>Message</h4>
              <Textarea
                id="message"
                name="message"
                placeholder="Message"
                rows={5}
                onChange={this.handleChange}
                value={this.state.message}
              />

              <Select
                id="didFindOut"
                name="didFindOut"
                rows={showMessage ? 3 : 1}
                onChange={this.handleChange}
                value={this.state.didFindOut}
              >
                <option value="" disabled={true}>
                  How did you find out about us?
                </option>
                <option value="Internet">Internet</option>
                <option value="Magazine">Magazine</option>
                <option value="Brochure">Brochure</option>
                <option value="Friend">Friend</option>
                <option value="Homeshow">Homeshow</option>
                <option value="Newspaper">Newspaper</option>
                <option value="Television">Television</option>
                <option value="Radio">Radio</option>
                <option value="Other">Other</option>
              </Select>

              <Box my={3}>
                <Button
                  small={true}
                  full={true}
                  inverted={true}
                  type="submit"
                  onSubmit={this.handleSubmit}
                >
                  {this.state.buttonMessage}
                </Button>
              </Box>
            </Fieldset>
          </Form>
        </Container>
      </Layout>
    )
  }
}
