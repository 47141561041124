import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea } from './Input'
import { yPad, xPad } from '../utils/theme'
import Form from './Form'

interface IContainerProps {
  noBorder: boolean
}

export const Container = styled<IContainerProps, any>('div')`
  ${yPad};
  ${xPad};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  margin: 30px auto;

  i {
    color: ${props => props.theme.colors.primary};
    font-style: normal;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

interface Props {
  children?: React.ReactChild
  title?: string
  showMessage?: boolean
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    header: '<h2>Request Your <i>Free</i> Quote Below</h2>'
  }
  render() {
    return (
      <Container {...this.props}>
        <div dangerouslySetInnerHTML={{ __html: this.props.header }} />
        <Form {...this.props} didFindOut={true} />
      </Container>
    )
  }
}
